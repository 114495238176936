<template>
  <div>
    <b-modal id="trade-url" centered hide-header ok-title="保存" cancel-title="取消" @ok="saveTradeUrl">
      <div class="p-3">
        <h4 class="text-white mt-2">
          请输入你的交易链接
          <a
            style="font-size: 11px;"
            target="_blank"
            :href="`https://steamcommunity.com/profiles/${user.info.steam_id}/tradeoffers/privacy`"
            >点此查看</a
          >
        </h4>
        <b-form-input
          v-model="steam_trade_url"
          class="trade-url-input mt-4"
          type="text"
          required
          placeholder="请输入交易链接"
        />
      </div>
    </b-modal>

    <alipay-verification-modal />

    <div class="card d-flex flex-row align-items-center">
      <b-avatar :src="user.info.avatar_url" rounded="lg" size="120" />
      <div class="profile__name">
        <div class="text-white mb-1">
          {{ user.info.name }}
        </div>
        <div class="flex flex-wrap">
          <div v-b-tooltip.hover.top.v-primary class="profile__steam-id mr-4" title="您的Steam数字ID">
            <a
              target="_blank"
              class="text-primary"
              :href="`https://steamcommunity.com/profiles/${user.info.steam_id}/`"
            >
              <fa :icon="['fab', 'steam']" />
              ID:{{ user.info.steam_id }}
            </a>
          </div>
          <div
            v-if="user.info.alipay_id"
            v-b-tooltip.hover.top.v-primary
            class="profile__steam-id"
            title="您的支付宝数字ID"
          >
            <a
              target="_blank"
              class="text-secondary"
              :href="`https://steamcommunity.com/profiles/${user.info.steam_id}/`"
            >
              <fa :icon="['fab', 'alipay']" style="color:#009BE2" />
              <span class="ml-1" style="color:#009BE2">ID:{{ user.info.alipay_id }}</span>
            </a>
          </div>
          <div v-if="boundMerchant" class="ml-3">
            <div
              v-for="(mer, k) in boundMerchant"
              :key="'mer-key--' + k"
              v-b-tooltip.hover.top.v-primary
              class="profile__steam-id"
              title="您绑定的商户平台ID"
            >
              <span target="_blank" class="text-secondary">
                <span>{{ mer.merchant_name }}</span>
                <span class="ml-1">ID: {{ mer.merchant_user_id }} </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <chip class="text-14 mr-4" solid style="min-width:120px">
          <div style="">
            <span class="text-white">{{ $currencySign }} {{ balance.integer }}</span>
            <span class="text-12 pl-1">.{{ balance.decimal }}</span>
          </div>
        </chip>
        <btn class="btn btn-outline-primary px-5 py-3" @click="$bvModal.show('topup')">充值</btn>
      </div>
    </div>

    <div class="d-flex mt-2">
      <div class="card col-2 mr-2 text-secondary" style="min-height: 318px">
        <div
          class="d-flex justify-content-start align-items-center py-4 pointer pl-4"
          @click="tab = 'user'"
          :class="{ 'text-white': tab === 'user' }"
        >
          <fa :icon="['far', 'user']" />
          <span class="ml-2">账户设置</span>
        </div>
        <div
          class="d-flex justify-content-start align-items-center py-4 pointer pl-4"
          @click="tab = 'steam'"
          :class="{ 'text-white': tab === 'steam' }"
        >
          <fa :icon="['fab', 'steam']" />
          <span class="ml-2">Steam设置</span>
        </div>
        <div
          class="d-flex justify-content-start align-items-center py-4 pointer pl-4"
          @click="tab = 'wallet'"
          :class="{ 'text-white': tab === 'wallet' }"
        >
          <wallet-svg width="17" height="17" />
          <span class="ml-2">钱包</span>
        </div>
        <div
          class="d-flex justify-content-start align-items-center py-4 pointer pl-4"
          @click="tab = 'transactions'"
          :class="{ 'text-white': tab === 'transactions' }"
        >
          <fa :icon="['fa', 'dollar-sign']" />
          <span class="ml-2">充值记录</span>
        </div>
      </div>

      <div v-if="tab === 'user'" class="card">
        <trade-url />

        <!-- <div class="p-4">
          <div class="d-flex align-items-center">
            <span class="text-secondary">邮箱</span>
          </div>
          <div class="d-flex align-items-center mt-3">
            <search-input v-model="email" style="flex:1" read-only hide-icon width="100%" />
          </div>
        </div>

        <div class="p-4">
          <div class="d-flex align-items-center">
            <span class="text-secondary">手机号</span>
          </div>
          <div class="d-flex align-items-center mt-3">
            <search-input v-model="mobile" style="flex:1" read-only hide-icon width="100%" />
          </div>
        </div> -->

        <div class="p-4">
          <div class="d-flex align-items-center">
            <span class="text-secondary">支付宝认证</span>
            <fa id="question-alipay" class="text-secondary pointer ml-2" :icon="['far', 'question-circle']" />
            <b-popover target="question-alipay" placement="top" triggers="hover focus" variant="secondary">
              为保证您的账户资金安全，您需要先完善实名信息，通过支付宝身份认证后才可使用充值功能。
            </b-popover>
          </div>
          <div class="d-flex align-items-center mt-3">
            <btn v-if="!realNameVerified" class="btn-primary" @click="verifyAlipay">开始认证</btn>
            <div v-else class="text-secondary text-success flex items-center">
              <check-green-svg />
              <span class="pl-2">已认证</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tab === 'steam'" class="card">
        <div class="p-4">
          <div class="d-flex align-items-center">
            <span class="text-white">Steam交易链接</span>
            <a
              class="btn btn-link text-primary text-small"
              :href="`https://steamcommunity.com/profiles/${user.info.steam_id}/tradeoffers/privacy`"
            >
              点此获得
            </a>
          </div>
          <div class="d-flex align-items-center mt-2">
            <search-input :value="steamTradeUrl" style="flex:1" read-only hide-icon width="100%" />
            <btn
              id="popover-btn-1"
              class="btn btn-outline-secondary px-5 py-2 ml-4"
              size="sm"
              @click="openTradeUrlModal"
            >
              修改
            </btn>
          </div>
        </div>

        <!-- <div class="p-4">
          <div class="d-flex align-items-center">
            <span class="text-white">Steam秘钥</span> 
          </div>
          <div class="d-flex align-items-center mt-2">
            <search-input :value="secretKey" style="flex:1" read-only hide-icon width="100%" />
            <btn id="popover-btn-1" class="btn btn-outline-secondary size='sm' px-5 py-2 ml-4">
              修改
            </btn>
          </div>
        </div> -->
      </div>

      <div v-if="tab === 'wallet'" class="card">
        <div class="p-4">
          <div class="d-flex align-items-center">
            <img src="@/assets/icon/metamask.png" width="24" alt="" />
            <span class="text-secondary ml-2">MetaMask</span>
          </div>
          <div class="d-flex align-items-center mt-4">
            <search-input :value="metamaskAddress" style="flex:1" read-only hide-icon width="100%" />
            <!-- <btn id="popover-btn-1" class="btn btn-outline-secondary px-5 py-2 ml-4" @click="disconnectMetamask">
              解绑
            </btn> -->
          </div>
          <btn
            class="btn-outline-primary mt-4"
            @click="connectMetamask"
            :loading="connecting"
            :disabled="connected || connecting"
            size="sm"
          >
            {{ connected ? '已绑定' : '绑定 MetaMask' }}
          </btn>
        </div>
      </div>

      <div v-if="tab === 'transactions'" class="card">
        <transactions />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { UserService } from '@/services/api'
import TradeUrl from './TradeUrl.vue'
import Transactions from './Transactions.vue'
import AlipayVerificationModal from './RealName/AlipayVerificationModal.vue'

export default {
  name: 'Profile',
  components: { TradeUrl, Transactions, AlipayVerificationModal },
  data() {
    return {
      steam_trade_url: null,
      tab: 'user',
      email: '',
      mobile: '',
      defaultTab: this.$router.currentRoute.query?.tab,
      connected: false,
      connecting: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'wallet']),
    boundMerchant() {
      if (!this.user.info?.bound_merchant) {
        return []
      }
      return this.user.info?.bound_merchant
    },
    realNameVerified() {
      if (!this.user.info) return false
      return this.user.info.real_name_verified
    },
    secretKey() {
      const l = this.user.info.steam_id.length
      let rv = this.user.info.steam_id
      rv = '***********'
      return rv
    },
    steamTradeUrl() {
      if (!this.user.info || !this.user.info.steam_trade_url) return '未设置'
      return this.user.info.steam_trade_url
    },
    metamaskAddress() {
      if (!this.wallet.address) return '未设置'
      return this.wallet.address
    },
    balance() {
      const rv = {
        integer: '0',
        decimal: '00',
      }
      const b = this.user.info.balance
      rv.integer = Math.floor(b) + ''
      const d = Math.round((b % rv.integer) * 100) + ''
      rv.decimal = d.length > 1 ? d : '0' + d
      return rv
    },
  },
  mounted() {
    if (this.defaultTab) {
      this.tab = this.defaultTab
    }

    if (this.$router.currentRoute.query?.verify) {
      this.$modal.show('alipay-verification')
    }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', val => {
        this.accountChangedHandler(val[0])
      })

      window.ethereum.on('chainChanged', this.chainChangedHandler)

      window.ethereum.on('disconnect', this.disconnectHandler)
    }
  },
  methods: {
    verifyAlipay() {
      this.$modal.show('alipay-verification')
    },
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    openTradeUrlModal() {
      this.steam_trade_url = this.user.info.steam_trade_url
      this.$bvModal.show('trade-url')
    },
    async saveTradeUrl() {
      try {
        const settings = {
          // hide_inventory: false,
          steam_trade_url: this.steam_trade_url,
        }
        await UserService.saveSetting(settings)
        this.toast('交易链接设置成功', 'success')
      } catch (e) {
        this.toast('交易链接设置失败，请检查交易链接')
      }
      this.$store.dispatch('LOAD_USER')
    },
    disconnectMetamask() {},
    connectMetamask() {
      if (window.ethereum) {
        this.connecting = true
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(res => {
            this.accountChangedHandler(res[0])
            this.connected = true
          })
          .finally(() => {
            this.connecting = false
          })
      } else {
        alert(
          'Please install MetaMask extension! \n\nhttps://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
        )
      }
    },
    accountChangedHandler(newAddress) {
      const addr = newAddress ?? ''
      this.$store.dispatch('SET_ADDRESS', addr)
      this.getUserBalance(addr.toString())
    },
    chainChangedHandler() {
      window.location.reload()
    },
    getUserBalance(address) {
      window.ethereum.request({ method: 'eth_getBalance', params: [address, 'latest'] }).then(res => {
        this.$store.dispatch('SET_BALANCE', res)
      })
    },
    disconnectHandler() {
      this.connected = false
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';
.trade-url-input {
  background-color: transparent;
  border-color: #434365;
}
.set_trade_url {
  cursor: pointer;
}
.profile {
  &__icon {
    color: #39c4e5;
    font-size: 20px;
  }
  &__steam-id {
    font-size: 14px;
  }
  &__steam-id {
    font-size: 14px;
  }
  &__trade-url {
    &-label {
      font-size: 14px;
    }
    user-select: none;
    padding: 0 2em;
    flex: 0 0 400px;
  }
  &__name {
    border-right: solid 1px $glutterColor;
    height: 125px;
    width: 100%;
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 0 2em;
    font-size: 1.4em;
    color: white;
  }

  &__card {
    height: 180px;
  }
}
</style>
