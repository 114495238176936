<template>
  <div class="card">
    <div class="d-flex align-items-center">
      <span class="text-secondary">交易链接</span>
      <button class="btn btn-link text-primary text-small" @click="generateTradeToken">
        重新生成
      </button>
      <fa id="question-1" class="text-secondary pointer" :icon="['far', 'question-circle']" />
      <b-popover target="question-1" placement="top" triggers="hover focus" variant="secondary">
        如果您不再希望通过已经发布的交易 URL 收到新的交易报价，创建一条新的 URL 将使旧有链接失效。
      </b-popover>
    </div>
    <div class="text-secondary mt-1" style="font-size:0.8rem">
      您可以将这条唯一的 URL 分享给其他 Kitty 用户以允许他们向您发送交易报价，即使他们不是您的好友。您也可以将这条 URL
      发布在任何地方。
    </div>
    <div class="d-flex align-items-center mt-3">
      <search-input v-model="tradeUrl" style="flex:1" read-only hide-icon width="100%" :loading="regenerateLoading" />
      <btn
        id="popover-btn-1"
        class="btn btn-outline-secondary px-5 py-2 ml-4"
        :disabled="!tradeUrl || regenerateLoading"
        @click="copyURL"
        size="sm"
      >
        复制
      </btn>
      <b-popover target="popover-btn-1" :show.sync="pop" triggers="" placement="top" variant="success">
        复制成功
      </b-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WEB_URL } from '@/config'
import { copyTextToClipboard } from '@/utils'
import { UserService } from '@/services/api'

export default {
  name: 'TradeUrl',
  data() {
    return {
      tradeUrl: '',
      regenerateLoading: false,
      pop: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    tradeToken() {
      if (!this.user.info) return ''
      return this.user.info.trade_url
    },
  },
  watch: {
    tradeToken(val) {
      this.tradeUrl = WEB_URL + '/offer/' + val + '/new'
    },
  },
  mounted() {
    if (this.user.info) {
      this.tradeUrl = WEB_URL + '/offer/' + this.user.info.trade_url + '/new'
    }
  },
  methods: {
    async copyURL() {
      try {
        await copyTextToClipboard(this.tradeUrl)

        this.pop = true
        setTimeout(() => {
          this.pop = false
        }, 1000)
      } catch (err) {
        this.$store.commit('SET_ERROR', '复制出错')
      }
    },
    async generateTradeToken() {
      const ans = await this.$bvModal.msgBoxConfirm(`你确定要重新生成交易链接吗?`, {
        title: '生成交易链接',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      if (!ans) {
        return
      }
      this.regenerateLoading = true
      UserService.generateTradeToken()
        .then(async () => {
          const { data } = await UserService.getUserInfo()
          if (data && data.trade_url) {
            this.tradeUrl = WEB_URL + '/offer/' + data.trade_url + '/new'
          }
        })
        .catch(() => {})
        .finally(() => {
          this.regenerateLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
