<template>
  <section>
    <div class="pay-history__content">
      <my-table class="text-9" :data="tableItems" :loading="loading" :cols="cols">
        <template v-slot:topup_value="{ value }">
          <span> {{ $currencySign }} {{ value }} </span>
        </template>

        <template v-slot:pay_type="{ value }">
          <fa v-if="value === 'alipay'" :icon="['fab', 'alipay']" />
          <fa v-if="value === 'wechat'" :icon="['fab', 'weixin']" />
          <span> {{ getPayName(value) }} </span>
        </template>

        <template v-slot:created_time="{ value }">
          <span>{{ new Date(value).toLocaleString() }}</span>
        </template>

        <template v-slot:status="{ item }">
          <tick-svg v-if="item.code" />
          <cross-circle-svg v-if="!item.code" />
          <span class="ml-2">{{ item.code ? '支付成功' : '支付失败' }}</span>
        </template>
      </my-table>
    </div>
  </section>
</template>

<script>
import { UserService } from '@/services/api'
import { errorParser } from '@/utils'

const COLS = [
  { label: '单号', value: 'order_no', fixed: '210' },
  { label: '支付方式', value: 'pay_type', fixed: '150' },
  { label: '金额', value: 'topup_value', fixed: '150' },
  { label: '时间', value: 'created_time' },
  { label: '状态', value: 'status', fixed: '150' },
]

export default {
  name: 'Transactions',
  data() {
    return {
      loading: false,
      tableItems: [],
      cols: COLS,
    }
  },
  created() {},
  mounted() {
    this.loadPage()
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    loadPage() {
      this.loading = true
      UserService.getTransactionHistory()
        .then(({ data }) => {
          this.tableItems = data
        })
        .catch(e => {
          this.toast(errorParser(e.response), 'danger')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPayName(type) {
      if (type === 'alipay') {
        return '支付宝'
      }
      if (type === 'wechat') {
        return '微信支付'
      }
      return type
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index';
</style>
