<template>
  <modal id="alipay-verification" variant="light" rounded="0" width="650px" @hide="onHide">
    <template #header>支付宝身份认证</template>
    <template #body>
      <form v-if="!qrCode" class="text-sm pb-4" @submit.prevent="submitVerification">
        <div class="text-center text-red-900 bg-red-100 py-3">
          <span class="">个人信息用于实名认证，一旦认证不能修改，请认真填写。</span>
        </div>
        <div class="flex flex-col items-center mt-5">
          <div class="flex items-center">
            <span class="shrink-0 text-[#9CA3AF]">真实姓名</span>
            <div class="w-[450px] pl-2">
              <input class="pl-2 flex-1 w-full h-10" v-model="name" required />
            </div>
          </div>
          <div class="flex items-center mt-3">
            <span class="shrink-0 text-[#9CA3AF]">身份证号</span>
            <div class="w-[450px] pl-2 relative">
              <input class="pl-2 flex-1 w-full h-10" v-model="id" required />
              <div v-if="idInvalid" class="absolute text-red-900">{{ idInvalid }}</div>
            </div>
          </div>
          <div class="mt-5 flex items-center">
            <input id="agree-box" type="checkbox" v-model="agreed" required />
            <label for="agree-box" class="pl-2 text-gray-600 m-0">
              我同意<a class="text-gray-600" href="/page/terms" target="_blank">《认证服务协议》</a>
            </label>
          </div>
          <button class="mt-3 px-5 py-2 bg-blue-400 flex items-center border-0" type="submit" :disabled="submitting || loading">
            <spinner v-if="submitting || loading" width="14" class="mr-2" />
            确认
          </button>
        </div>
      </form>
      <div v-if="qrCode">
        <div class="text-center text-green-900 bg-green-100 py-3">
          <span class="">请打开支付宝，使用扫一扫功能扫描二维码，按照提示进行操作。</span>
        </div>
        <div class="flex flex-col items-center mt-5">
          <div>
            <img :src="qrCode" class="" alt="" />
          </div>
        </div>
        <div class="mt-4 flex flex-col items-center">
          <alipay-logo-text-svg />
          <div class="text-gray-400 text-sm mt-1">本服务由支付宝提供</div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { UserService } from '@/services/api'
import { errorParser } from '@/utils'

export default {
  name: 'AlipayVerificationModal',
  data() {
    return {
      agreed: false,
      submitting: false,
      loading: false,
      name: '',
      id: '',
      idInvalid: false,
      qrCode: '',
      polling: null,
    }
  },
  watch: {
    id(v) {
      if (v) {
        this.idInvalid = false
      }
    },
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        variant: type,
        solid: true,
      })
    },
    onHide() {
      this.agreed = false
      this.name = ''
      this.id = ''
      this.idInvalid = false
      this.qrCode = ''
      if (this.polling !== null) {
        clearInterval(this.polling)
      }
    },
    submitVerification(e) {
      const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
      if (!reg.test(this.id)) {
        this.idInvalid = '请输入正确的身份证号码'
        return
      }
      this.submitting = true
      const params = {
        real_name: this.name,
        cert_no: this.id,
      }
      UserService.alipayVerify(params)
        .then(({ data }) => {
          this.fetchQrCode(data.id)
        })
        .catch(e => {
          this.toast(errorParser(e.response).message)
        })
        .finally(() => {
          this.submitting = false
        })
    },
    fetchQrCode(id) {
      this.loading = true
      UserService.getVerificationQrCode(id)
        .then(({ data }) => {
          this.qrCode = data.qr_code
          this.polling = setInterval(() => {
            if (this.qrCode) {
              UserService.getVerificationResult(id).then(({ data }) => {
                if (data.status) {
                  this.isSuccess = true
                  this.toast('充值成功', 'success')
                  setTimeout(() => this.onHide(), 2000)
                }
              })
            }
          }, 8000)
        })
        .catch(e => {
          this.toast(errorParser(e.response).message)
        })
        .finally(() => {
          this.loading = false
        })
      this.getVerificationResult(id)
    },
    getVerificationResult(id) {
      this.loading = true
      UserService.getVerificationResult(id)
        .then(() => {})
        .catch(e => {
          this.toast(errorParser(e.response).message)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
